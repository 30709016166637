import { ApolloError, ApolloQueryResult, OperationVariables, useApolloClient, useLazyQuery } from '@apollo/client'
import { Api, DefaultAuthContextType, SharedUtils } from '@walter/shared'
import { WebLogger, useWebAuth } from '@walter/shared-web'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'

type LoginValues = { email: string; phoneNumber: string; password: string }

interface AuthContextType extends Omit<DefaultAuthContextType, 'login'> {
  login: (values: LoginValues) => Promise<void>
  currentUser: Api.GetMeResidentWebQuery['me']
  hasConfirmedEmailPhoneWithEmail: (email: string) => Promise<Api.HasConfirmedEmailPhoneQuery['hasConfirmedEmailPhone']>
  hasConfirmedEmailPhoneWithPhoneNumber: (
    phoneNumber: string,
  ) => Promise<Api.HasConfirmedEmailPhoneQuery['hasConfirmedEmailPhone']>
  hasConfirmedEmailPhone: Api.HasConfirmedEmailPhoneQuery['hasConfirmedEmailPhone']
  loginEmail: string
  loginPhoneNumer: string
  setLoginEmail: (email: string) => void
  setLoginPhoneNumber: (phoneNumber: string) => void
  errorCurrentUserQuery?: ApolloError
  loadingCurrentUserQuery: boolean
  refetchCurrentUserQuery: (
    variables?: Partial<OperationVariables>,
  ) => Promise<ApolloQueryResult<Api.GetMeResidentWebQuery>>
  logout: () => Promise<void>
  token: string
}

const AuthContext = React.createContext({} as AuthContextType)

export default AuthContext

interface AuthContextProps {
  children: React.ReactNode
}

export function AuthProvider({ children }: AuthContextProps) {
  const client = useApolloClient()
  const navigate = useNavigate()
  const [loginEmail, setLoginEmail] = React.useState<string | undefined>(undefined)
  const [loginPhoneNumer, setLoginPhoneNumber] = React.useState<string | undefined>(undefined)
  const [hasConfirmedEmailPhone, setHasConfirmedEmailPhone] =
    React.useState<Api.HasConfirmedEmailPhoneQuery['hasConfirmedEmailPhone']>(null)

  const [loginResidentMutation] = Api.useLoginResidentWebMutation()

  const { token, login: loginSharedWeb, logout: logoutSharedWeb, isSessionExpired } = useWebAuth()

  const [
    getCurrentUser,
    { data, loading: loadingCurrentUserQuery, error: errorCurrentUserQuery, refetch: refetchCurrentUserQuery },
  ] = useLazyQuery<Api.GetMeResidentWebQuery>(Api.GetMeResidentWebDocument)

  // @ts-ignore Intended type casting
  const currentUser = data?.me as Api.getMeResidentWeb

  React.useEffect(() => {
    const sessionIsExpired = isSessionExpired()
    if (token && !sessionIsExpired) {
      getCurrentUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useLazyQuery, token])

  React.useEffect(() => {
    if (!currentUser && !loadingCurrentUserQuery && token && isSessionExpired()) {
      logout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, loadingCurrentUserQuery])

  React.useEffect(() => {
    if (currentUser?.preferedLanguage) {
      if (localStorage.getItem('i18nextLng') === 'debug') return
      localStorage.setItem('i18nextLng', currentUser.preferedLanguage)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  async function login({ phoneNumber, email, password }: LoginValues) {
    const { data } = await loginResidentMutation({
      variables: {
        email,
        phoneNumber,
        password,
        preferedLanguage: null,
      },
    })

    const token = data?.signinResident?.token
    const user = data?.signinResident?.user
    const refreshToken = data?.signinResident?.refreshToken

    if (token) {
      loginSharedWeb(token, refreshToken)
      // Fixes a races condition where the token does not have time to be set in the local storage (would have to login twice after being kicked out)
      await SharedUtils.wait(500)
    }

    if (user) {
      client.writeQuery({ query: Api.GetMeResidentWebDocument, data: { me: user } })
    }
  }

  async function logout() {
    logoutSharedWeb()
    client.stop()
    await client.cache.reset().catch(WebLogger.captureError)
    await client.resetStore().catch(WebLogger.captureError)
    navigate('/auth/login')
  }

  async function hasConfirmedEmailPhoneWithEmail(email: string) {
    const {
      data: { hasConfirmedEmailPhone },
    } = await client.query<Api.HasConfirmedEmailPhoneQuery>({
      query: Api.HasConfirmedEmailPhoneDocument,
      variables: { email },
    })

    setHasConfirmedEmailPhone(hasConfirmedEmailPhone)

    return hasConfirmedEmailPhone
  }

  async function hasConfirmedEmailPhoneWithPhoneNumber(phoneNumber: string) {
    const {
      data: { hasConfirmedEmailPhone },
    } = await client.query<Api.HasConfirmedEmailPhoneQuery>({
      query: Api.HasConfirmedEmailPhoneDocument,
      variables: { phoneNumber },
    })

    setHasConfirmedEmailPhone(hasConfirmedEmailPhone)

    return hasConfirmedEmailPhone
  }

  const register = async () => {
    // Can't self register for now
  }

  const value = {
    currentUser,
    errorCurrentUserQuery,
    loadingCurrentUserQuery,
    refetchCurrentUserQuery,
    login,
    logout,
    token,
    hasConfirmedEmailPhoneWithEmail,
    hasConfirmedEmailPhoneWithPhoneNumber,
    hasConfirmedEmailPhone,
    loginEmail,
    loginPhoneNumer,
    setLoginEmail,
    setLoginPhoneNumber,
    register,
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
