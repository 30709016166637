import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { getCurrentUser } from './api'

export async function verifyPropertyIdLoader(args: LoaderFunctionArgs) {
  try {
    const currentUser = await getCurrentUser()
    if (!currentUser) {
      throw new Error('Unauthenticated')
    }
    const allPropertyIds = currentUser.ownedProperties
      .map((unit) => unit.id)
      .concat(currentUser.livingProperty?.id ?? '')
      .filter(Boolean)

    const propertyId = args.params.propertyId
    if (propertyId && allPropertyIds.includes(propertyId)) {
      return null
    }
    throw new Error(`/`, { cause: 'redirect-to-home' })
  } catch (err) {
    if (err instanceof Error) {
      if (err.cause === 'redirect-to-home') {
        throw redirect(err.message)
      }
      if (err.message === 'Unauthenticated') {
        const token = localStorage.getItem('token')
        const refreshToken = localStorage.getItem('refreshToken')
        localStorage.clear()
        throw redirect(`/auth/login${token || refreshToken ? '?error=session-expired-please-login-again' : ''}`)
      }
    }
    console.error('Error:', 'verifyPropertyIdLoader.ts', err)
    return null
  }
}
