import * as React from 'react'
import { RouteObject, createBrowserRouter, redirect } from 'react-router-dom'
import { DashboardLayout } from '../layouts/DashboardLayout'
import { ErrorPage } from '../pages/error-page'
import { RootProvider } from '../providers/RootProvider'
import { authenticationLoader } from '../utils/loaders/authenticationLoader'
import { healthCheckLoader } from '../utils/loaders/healthCheckLoader'
import { unauthenticatedLoader } from '../utils/loaders/unauthenticatedLoader'
import { redirectToNewsLoader } from '../utils/loaders/redirectToNewsLoader'
import { loginForRetoolLoader } from '../utils/loaders/loginForRetoolLoader'
import { verifyPropertyIdLoader } from '../utils/loaders/verifyPropertyIdLoader'

const AUTH_ROUTES: RouteObject[] = [
  {
    path: 'login',
    loader: unauthenticatedLoader,
    lazy: async () => {
      const Login = await import('./auth/login')
      return { Component: Login.default }
    },
  },
  {
    path: 'password',
    loader: unauthenticatedLoader,
    lazy: async () => {
      const Password = await import('./auth/password')
      return { Component: Password.default }
    },
  },
  {
    path: 'phoneNumber',
    loader: unauthenticatedLoader,
    lazy: async () => {
      const EnterPhoneNumber = await import('./auth/phoneNumber')
      return { Component: EnterPhoneNumber.default }
    },
  },
  {
    path: 'forgot-password',
    lazy: async () => {
      const ForgotPassword = await import('./auth/forgot-password')
      return { Component: ForgotPassword.default }
    },
  },
  {
    path: 'loginForRetool',
    loader: loginForRetoolLoader,
    lazy: async () => {
      const LoginForRetool = await import('./auth/loginForRetool')
      return { Component: LoginForRetool.default }
    },
  },
  {
    path: 'confirmEmail',
    lazy: async () => {
      const ConfirmEmail = await import('./auth/confirmEmail')
      return { Component: ConfirmEmail.default }
    },
  },
  {
    path: 'confirmPhoneNumber',
    lazy: async () => {
      const ConfirmPhoneNumber = await import('./auth/confirmPhoneNumber')
      return { Component: ConfirmPhoneNumber.default }
    },
  },
  {
    path: 'createPassword',
    lazy: async () => {
      const CreatePassword = await import('./auth/createPassword')
      return { Component: CreatePassword.default }
    },
  },
  {
    path: 'reset-password',
    lazy: async () => {
      const ResetPassword = await import('./auth/reset-password')
      return { Component: ResetPassword.default }
    },
  },
  {
    path: '*',
    loader: () => {
      throw redirect('/auth/login')
    },
  },
]

const DASHBOARD_ROUTES: RouteObject[] = [
  {
    path: ':propertyId',
    loader: verifyPropertyIdLoader,
    shouldRevalidate, // Calls the loader on every child navigation
    children: [
      {
        path: 'news',
        lazy: async () => {
          const News = await import('./news')
          return { Component: News.default }
        },
      },
      {
        path: 'chat',
        lazy: async () => {
          const Chat = await import('./chat')
          return { Component: Chat.default }
        },
        children: [
          {
            path: ':channelUrl',
            lazy: async () => {
              const ChannelUrl = await import('./chat/:channelUrl')
              return { Component: ChannelUrl.default }
            },
          },
        ],
      },
      {
        path: 'calendar',
        lazy: async () => {
          const Calendar = await import('./calendar')
          return { Component: Calendar.default }
        },
      },
      {
        path: 'contacts',
        lazy: async () => {
          const Contacts = await import('./contacts')
          return { Component: Contacts.default }
        },
      },
      {
        path: 'rules',
        lazy: async () => {
          const Rules = await import('./rules')
          return { Component: Rules.default }
        },
      },
      {
        path: 'amenities',
        lazy: async () => {
          const Amenities = await import('./amenities')
          return { Component: Amenities.default }
        },
      },
      {
        path: 'packages',
        lazy: async () => {
          const Packages = await import('./packages')
          return { Component: Packages.default }
        },
      },
      {
        path: 'serviceRequests',
        lazy: async () => {
          const ServiceRequests = await import('./serviceRequests')
          return { Component: ServiceRequests.default }
        },
      },
      {
        path: 'files',
        lazy: async () => {
          const Files = await import('./files')
          return { Component: Files.default }
        },
      },
      {
        path: 'files/:folderId',
        lazy: async () => {
          const Files = await import('./files')
          return { Component: Files.default }
        },
      },
      {
        path: 'marketplace',
        lazy: async () => {
          const Marketplace = await import('./marketplace')
          return { Component: Marketplace.default }
        },
      },
      {
        path: 'faq',
        lazy: async () => {
          const Faq = await import('./faq')
          return { Component: Faq.default }
        },
      },
      {
        path: 'otherResidents',
        lazy: async () => {
          const OtherResidents = await import('./otherResidents')
          return { Component: OtherResidents.default }
        },
      },
      {
        path: 'unitDetails',
        lazy: async () => {
          const UnitDetails = await import('./unitDetails')
          return { Component: UnitDetails.default }
        },
      },
      {
        path: 'accountStatements',
        lazy: async () => {
          const AccountStatements = await import('./accountStatements')
          return { Component: AccountStatements.default }
        },
      },
      {
        path: 'profile',
        lazy: async () => {
          const Profile = await import('./profile')
          return { Component: Profile.default }
        },
      },
      {
        index: true,
        loader: redirectToNewsLoader,
        lazy: async () => {
          const Index = await import('./index')
          return { Component: Index.default }
        },
      },
      {
        path: '*',
        lazy: async () => {
          const { NoMatchPage } = await import('../pages/no-match-page')
          return { Component: NoMatchPage }
        },
      },
    ],
  },
  {
    path: 'profile',
    lazy: async () => {
      const Profile = await import('./profile')
      return { Component: Profile.default }
    },
  },
  {
    index: true,
    loader: redirectToNewsLoader,
    lazy: async () => {
      const Index = await import('./index')
      return { Component: Index.default }
    },
  },
  {
    path: '*',
    lazy: async () => {
      const { NoMatchPage } = await import('../pages/no-match-page')
      return { Component: NoMatchPage }
    },
  },
]

const ROUTES: RouteObject[] = [
  {
    path: '*',
    element: <RootProvider />,
    errorElement: <ErrorPage />,
    loader: healthCheckLoader,
    shouldRevalidate: shouldNotRevalidate,
    children: [
      {
        element: <DashboardLayout />,
        errorElement: <ErrorPage />,
        loader: authenticationLoader,
        shouldRevalidate, // Calls the loader on every child navigation
        children: DASHBOARD_ROUTES,
      },
      {
        path: 'auth/*',
        errorElement: <ErrorPage />,
        lazy: async () => {
          const { AuthLayout } = await import('../layouts/AuthLayout')
          return { Component: AuthLayout }
        },
        children: AUTH_ROUTES,
      },
      {
        path: 'property-removal/:propertyId/:tokenRemoval',
        lazy: async () => {
          const PropertyRemoval = await import('./propertyRemoval')
          return { Component: PropertyRemoval.default }
        },
      },
      {
        path: 'open-source-attributions',
        lazy: async () => {
          const OpenSourceAttributions = await import('./open-source-attributions')
          return { Component: OpenSourceAttributions.default }
        },
      },
      {
        path: 'temporarily-unavailable',
        lazy: async () => {
          const TemporarilyUnavailable = await import('./temporarily-unavailable')
          return { Component: TemporarilyUnavailable.default }
        },
      },
    ],
  },
]

export const router = createBrowserRouter(ROUTES)

function shouldRevalidate() {
  return true
}

function shouldNotRevalidate() {
  return false
}
