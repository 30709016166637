import React, { forwardRef, useImperativeHandle } from 'react'
import { t } from '../../utils'
import { ButtonGroup } from '../ButtonGroup'
import { Modal } from '../Modal'
import { Button } from './../Button'

export const AskManagerModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = React.useState(false)

  useImperativeHandle(ref, () => ({
    open() {
      setIsOpen(true)
    },
    close() {
      setIsOpen(false)
    },
  }))

  return (
    <Modal title={t('modal:ask-manager-modal-title')} visible={isOpen} close={() => setIsOpen(false)}>
      <>
        <p>{t('modal:ask-manager-modal-description')}</p>
        <ButtonGroup>
          <Button theme="tertiary" onClick={() => setIsOpen(false)}>
            {t('general:okay')}
          </Button>
        </ButtonGroup>
      </>
    </Modal>
  )
})
