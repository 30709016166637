import { redirect } from 'react-router-dom'
import { isSessionValid } from '../isSessionValid'
import { getCurrentUser } from './api'

export async function unauthenticatedLoader() {
  try {
    const currentUser = await getCurrentUser()
    if (!!currentUser && isSessionValid()) {
      throw new Error('Authenticated')
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      if (err.message === 'Authenticated') {
        throw redirect('/')
      }
      if (err.message.startsWith('Forbidden resource')) {
        if (localStorage.getItem('token') || localStorage.getItem('refreshToken')) {
          localStorage.clear()
        }
        return null
      }
    }
    console.error('Error:', 'unauthenticatedLoader.ts', err)
    return null
  }
}
