import dayjs from 'dayjs'
import { getHoursFromMs, getMillisecondsFromMsTime, getMinutesFromMs, getSecondsFromMs } from './times'
import { GetAvailableDatesForAmenityQueryQuery, GetTimeSlotsForDaySharedResidentQuery } from '../api'

export function getBookingArgDataForDate(
  selectedStartDate: Date,
  endDateValue: Date,
  availableDates: Partial<GetAvailableDatesForAmenityQueryQuery['getAvailableDatesForAmenity'][number]>[],
) {
  const parsedStart = dayjs(
    new Date(
      dayjs(selectedStartDate).get('year'),
      dayjs(selectedStartDate).get('month'),
      dayjs(selectedStartDate).get('date'),
    ),
  ).toISOString()
  const parsedEnd = dayjs(
    new Date(dayjs(endDateValue).get('year'), dayjs(endDateValue).get('month'), dayjs(endDateValue).get('date')),
  ).toISOString()

  const bookingOptionId = availableDates.find((dateData) => {
    return (
      dayjs(dateData.date).get('year') === selectedStartDate.getFullYear() &&
      dayjs(dateData.date).get('month') === selectedStartDate.getMonth() &&
      dayjs(dateData.date).get('date') === selectedStartDate.getDate()
    )
  })?.bookingOptionId as string
  return { bookingOptionId, parsedStart, parsedEnd }
}

export function getBookingArgDataForSingleDateWithTime(
  bookingHours: Partial<GetTimeSlotsForDaySharedResidentQuery['getTimeSlotsForDayV2'][number]>,
  selectedStartDate: Date,
) {
  const startMS = bookingHours.startMS || 0
  const endMS = bookingHours.endMS || 0

  return {
    bookingOptionId: bookingHours.bookingOptionId as string,
    parsedStart: dayjs(
      new Date(
        dayjs(selectedStartDate).get('year'),
        dayjs(selectedStartDate).get('month'),
        dayjs(selectedStartDate).get('date'),
        getHoursFromMs(startMS),
        getMinutesFromMs(startMS),
        getSecondsFromMs(startMS),
        getMillisecondsFromMsTime(startMS),
      ),
    ).toISOString(),
    parsedEnd: dayjs(
      new Date(
        dayjs(selectedStartDate).get('year'),
        dayjs(selectedStartDate).get('month'),
        dayjs(selectedStartDate).get('date'),
        getHoursFromMs(endMS),
        getMinutesFromMs(endMS),
        getSecondsFromMs(endMS),
        getMillisecondsFromMsTime(endMS),
      ),
    ).toISOString(),
  }
}
