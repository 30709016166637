/**
 * Separated the modal and the content because we're reusing the content in another place
 */
import { Api } from '@walter/shared'
import React, { useContext, useRef } from 'react'
import { Fieldset, FieldsetItem, Input } from '..'
import { ToastContext } from '../../contexts'
import { t } from '../../utils'
import { AskManagerModal } from '../AskManagerModal'
import { Button } from '../Button'

type ConfirmEmailContentProps = {
  userEmail: string
  codeConfirmed?: () => void
}

export const ConfirmEmailContent = (props: ConfirmEmailContentProps) => {
  const isSendingCodeRef = useRef(false)
  const { showToast } = useContext(ToastContext)

  const codeInputRef = useRef<HTMLInputElement>(null)
  const askManagerModalRef = useRef()

  const [sendEmailVerificationCodeMutation, { loading: sendingVerificationCode }] =
    Api.useSendAuthVerificationCodeEmailMutation()

  const [confirmEmailVerificationCodeMutation, { loading: confirmingVerificationCode }] =
    Api.useVerifyAuthCodeEmailMutation()

  React.useEffect(() => {
    if (isSendingCodeRef.current) return
    sendCode()
  }, [])

  async function sendCode() {
    try {
      isSendingCodeRef.current = true
      await sendEmailVerificationCodeMutation({ variables: { email: props.userEmail } })
      showToast('positive', t('toast:confirm-email-code-sent-title'), t('toast:confirm-email-code-sent-description'))
    } catch (error) {
      showToast('negative', error instanceof Error ? error?.message : t('error'))
    } finally {
      isSendingCodeRef.current = false
    }
  }

  async function confirmCode() {
    try {
      await confirmEmailVerificationCodeMutation({
        variables: { email: props.userEmail, code: codeInputRef.current?.value ?? '' },
      })
      showToast(
        'positive',
        t('toast:confirm-email-code-confirmed-title'),
        t('toast:confirm-email-code-confirmed-description'),
      )
      if (props.codeConfirmed) {
        props.codeConfirmed()
      }
    } catch (error) {
      showToast('negative', error instanceof Error ? error?.message : t('error'))
    }
  }

  return (
    <>
      <p>
        {t('general:confirm-email-description')} <b>{props.userEmail}</b>
      </p>
      <Fieldset>
        <FieldsetItem>
          <Input
            // @ts-ignore TODO: typing issue ref
            ref={codeInputRef}
            placeholder={t('general:enter-code-input-placeholder')}
            rightAction={{
              isLoading: confirmingVerificationCode,
              label: t('general:confirm-code'),
              theme: 'primary',
              onClick: confirmCode,
            }}
          />
        </FieldsetItem>
      </Fieldset>
      <Button theme="link" isLoading={sendingVerificationCode} onClick={sendCode}>
        {t('general:resend-code')}
      </Button>
      <br />
      {/* @ts-ignore TODO: typing issue askManagerModalRef */}
      <Button theme="link" onClick={() => askManagerModalRef.current.open()}>
        {t('general:not-your-email')}
      </Button>
      <AskManagerModal ref={askManagerModalRef} />
    </>
  )
}
