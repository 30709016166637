import React from 'react'

type LastLocationContextType = {
  lastLocation: string
  setLastLocation: React.Dispatch<React.SetStateAction<string>>
}

export const LastLocationContext = React.createContext({} as LastLocationContextType)

export function LastLocationProvider({ children }: { children: React.ReactNode }) {
  const [lastLocation, setLastLocation] = React.useState(localStorage.lastLocation)

  return (
    <LastLocationContext.Provider value={{ lastLocation, setLastLocation }}>{children}</LastLocationContext.Provider>
  )
}
