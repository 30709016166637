import { Api } from '@walter/shared'
import { apolloClient } from '../apollo-client'

export async function getCurrentUser() {
  const { data } = await apolloClient.query<Api.GetMeResidentWebQuery | undefined, Api.GetMeResidentWebQueryVariables>({
    query: Api.GetMeResidentWebDocument,
  })
  return data?.me
}

export async function getPropertyTools(propertyId: string) {
  const { data } = await apolloClient.query<
    Api.ProjectDetailedResidentWebQuery,
    Api.ProjectDetailedResidentWebQueryVariables
  >({
    query: Api.ProjectDetailedResidentWebDocument,
    variables: {
      where: {
        id: propertyId,
      },
    },
  })

  return data?.property?.building?.project?.tools ?? []
}
