import { Colors, Spacing } from '@walter/shared'
import { Flex, globalStyles, Icon, typography } from '@walter/shared-web'
import { rgba } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { t } from '../utils/i18n'

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  background-color: ${rgba(Colors.greyLight, 0.2)};
  border-radius: 50%;
  margin-bottom: ${Spacing.medium};
  color: ${Colors.greyLight};
  ${globalStyles.square('44px')};
`

type OtherResidentAddRequestContentProps = {
  isOwner: boolean
  requesterfullName: string
  requesterAddress: string
  project: string
  address: string
  appartmentNumber: string
}

export const OtherResidentAddRequestContent = ({
  isOwner,
  requesterfullName,
  requesterAddress,
  project,
  address,
  appartmentNumber,
}: OtherResidentAddRequestContentProps) => {
  return (
    <>
      {isOwner && (
        <Flex
          css={`
            height: 70px;
          `}
        >
          <IconWrap
            css={`
              margin: 0 10px 0 10px;
            `}
          >
            <Icon
              icon="warning"
              size="small"
              css={`
                width: 44px;
              `}
            />
          </IconWrap>
          <p
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-size: ${typography.fontSizes.regular};
              font-weight: ${typography.fontWeights.black};
              color: red;
              width: 90%;
            `}
          >
            <span
              // eslint-disable-next-line react/no-unknown-property
              css={`
                font-weight: ${typography.fontWeights.black};
                color: red;
              `}
            >
              {t('general:warning')}
            </span>
            <span
              // eslint-disable-next-line react/no-unknown-property
              css={`
                font-weight: ${typography.fontWeights.regular};
                color: black;
              `}
            >
              {': ' + t('general:by-accepting-request') + ' '}
            </span>
            <span
              // eslint-disable-next-line react/no-unknown-property
              css={`
                font-weight: ${typography.fontWeights.bold};
                color: black;
              `}
            >
              {requesterAddress}
            </span>
          </p>
        </Flex>
      )}
      <Flex
        css={`
          height: 70px;
        `}
      >
        <IconWrap
          css={`
            margin: 0 10px 0 10px;
          `}
        >
          <Icon icon="profile" size="small" />
        </IconWrap>
        <p
          // eslint-disable-next-line react/no-unknown-property
          css={`
            font-size: ${typography.fontSizes.regular};
            width: 90%;
          `}
        >
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.regular};
            `}
          >
            {t('general:profile-resident-wants-to-add-you-description-who')}
          </span>
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.bold};
            `}
          >
            {` ${requesterfullName}`}
          </span>
        </p>
      </Flex>
      <Flex
        css={`
          height: 70px;
        `}
      >
        <IconWrap
          css={`
            margin: 0 10px 0 10px;
          `}
        >
          <Icon icon="settings" size="small" />
        </IconWrap>
        <p
          // eslint-disable-next-line react/no-unknown-property
          css={`
            font-size: ${typography.fontSizes.regular};
            width: 90%;
          `}
        >
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.regular};
            `}
          >
            {t('general:profile-resident-wants-to-add-you-description-project-where')}
          </span>
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.bold};
            `}
          >
            {` ${project}`}
          </span>
        </p>
      </Flex>
      <Flex
        css={`
          height: 70px;
        `}
      >
        <IconWrap
          css={`
            margin: 0 10px 0 10px;
          `}
        >
          <Icon icon="house" size="small" />
        </IconWrap>
        <p
          // eslint-disable-next-line react/no-unknown-property
          css={`
            font-size: ${typography.fontSizes.regular};
            width: 90%;
          `}
        >
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.regular};
            `}
          >
            {t('general:profile-resident-wants-to-add-you-description-address-where')}
          </span>
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.bold};
            `}
          >
            {` ${address}`}
          </span>
        </p>
      </Flex>
      <Flex
        css={`
          height: 70px;
        `}
      >
        <IconWrap
          css={`
            margin: 0 10px 0 10px;
          `}
        >
          <Icon icon="house" size="small" />
        </IconWrap>
        <p
          // eslint-disable-next-line react/no-unknown-property
          css={`
            font-size: ${typography.fontSizes.regular};
            width: 90%;
          `}
        >
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.regular};
            `}
          >
            {t('general:profile-resident-wants-to-add-you-description-appartment-number-where')}
          </span>
          <span
            // eslint-disable-next-line react/no-unknown-property
            css={`
              font-weight: ${typography.fontWeights.bold};
            `}
          >
            {` ${appartmentNumber}`}
          </span>
        </p>
      </Flex>
    </>
  )
}
