import { useAtomValue } from 'jotai'
import { useMemo } from 'react'
import { MessagingService } from '../MessagingService'
import { publicChannelsMapAtom } from '../atoms'

/**
 * @warning Must be called after `useMessagingService`
 * @returns Unread count of messages for the current user
 */
export function useResidentUnreadMessagesCount(selectedPropertyId: string) {
  const publicChannelsMap = useAtomValue(publicChannelsMapAtom)

  const unreadMessagesCountByPropertyIdMap = useMemo(() => {
    const unreadByProject = new Map<string, number>()
    const publicChannels = Array.from(publicChannelsMap.values())
    for (const channel of publicChannels) {
      if (MessagingService.channelIsDm(channel.url)) {
        const projectId = MessagingService.projectIdFromChannelUrl(channel.url)
        const unread = unreadByProject.get(projectId) ?? 0
        unreadByProject.set(projectId, unread + channel.unreadMessageCount)
      }
    }
    const unreadByProperty = new Map<string, number>()
    for (const channel of publicChannels) {
      if (MessagingService.channelIsUnit(channel.url)) {
        const propertyId = MessagingService.entityIdFromChannelUrl(channel.url)
        const projectId = MessagingService.projectIdFromChannelUrl(channel.url)
        const unread = unreadByProperty.get(propertyId) ?? 0 + (unreadByProject.get(projectId) ?? 0)
        const unreadChannelCount = channel.unreadMessageCount ?? 0
        unreadByProperty.set(propertyId, unread + unreadChannelCount)
      }
    }
    return unreadByProperty
  }, [publicChannelsMap])

  const allUnreadMessagesCount = useMemo(() => {
    let unreadMessages = 0
    for (const unreadMessagesCountByPropertyIds of unreadMessagesCountByPropertyIdMap) {
      const numOfUnreadMessages = unreadMessagesCountByPropertyIds[1]
      unreadMessages += numOfUnreadMessages
    }
    return unreadMessages
  }, [unreadMessagesCountByPropertyIdMap])

  const currentUnreadMessagesCount = useMemo(() => {
    return unreadMessagesCountByPropertyIdMap.get(selectedPropertyId) ?? 0
  }, [unreadMessagesCountByPropertyIdMap, selectedPropertyId])

  const otherProjectsUnreadMessagesCount = useMemo(() => {
    return allUnreadMessagesCount - currentUnreadMessagesCount
  }, [allUnreadMessagesCount, currentUnreadMessagesCount])

  return {
    unreadMessagesCountByPropertyIdMap,
    allUnreadMessagesCount,
    currentUnreadMessagesCount,
    otherProjectsUnreadMessagesCount,
  }
}
