import { Api, ResidentsUtils, SharedUtils, Types } from '@walter/shared'
import { useUpdateTimezone } from '@walter/shared-web'
import React, { useMemo } from 'react'
import AuthContext from './Auth'
import ProjectContext from './Project'
import PropertyContext from './Property'

type CurrentUserContextType = {
  currentUser: Api.GetMeResidentWebQuery['me']
  currentResidentType: Types.RESIDENT_TYPE
  updateCurrentUser: (data: Api.UserUpdateInput) => Promise<void>
}

const CurrentUserContext = React.createContext({} as CurrentUserContextType)

export default CurrentUserContext

type CurrentUserProviderType = {
  children: React.ReactNode
}

export function CurrentUserProvider({ children }: CurrentUserProviderType) {
  const { currentUser } = React.useContext(AuthContext)
  const { selectedProperty } = React.useContext(PropertyContext)
  const { currentProject } = React.useContext(ProjectContext)

  // TODO: Should it be UpdateCurrentUserResidentWebDocument ?
  const [updateCurrentUserMutation] = Api.useUpdateCurrentUserManagerWebMutation()

  useUpdateTimezone(currentUser as { timezone: string }, updateCurrentUser)

  async function updateCurrentUser(data: Api.UserUpdateInput) {
    await updateCurrentUserMutation({ variables: { data } })
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  }

  const currentUserFormattedForCurrentProperty = useMemo(() => {
    if (!currentProject || !selectedProperty) {
      return currentUser
    }
    // TODO: Fix unknown type casting
    return SharedUtils.formatUserToOnlyHaveHisDataBasedOnCurrentProject(
      currentUser as unknown as Parameters<typeof SharedUtils.formatUserToOnlyHaveHisDataBasedOnCurrentProject>[0],
      [currentProject] as { id: string; building: { id: string } }[],
    )
  }, [selectedProperty, currentProject, currentUser])

  const currentResidentType = useMemo(() => {
    return ResidentsUtils.getResidentType(currentUserFormattedForCurrentProperty)
  }, [currentUserFormattedForCurrentProperty])

  return (
    <CurrentUserContext.Provider
      value={{
        updateCurrentUser,
        currentUser: currentUserFormattedForCurrentProperty as Api.GetMeResidentWebQuery['me'],
        currentResidentType: currentResidentType ?? Types.RESIDENT_TYPE.familyMember,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  )
}
