import { MessagingService, useMessagingService } from '@walter/shared'
import { LastLocationContext } from '@walter/shared-web'
import * as React from 'react'
import { useLocation } from 'react-router-dom'
import AuthContext from '../contexts/Auth'
import { ConversationsProvider } from '../contexts/Conversations'
import { CurrentUserProvider } from '../contexts/CurrentUser'
import { ProjectProvider } from '../contexts/Project'
import { PropertyProvider } from '../contexts/Property'

const messagingService = new MessagingService()

export function DashboardProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = React.useContext(AuthContext)
  useMessagingService({
    messagingService,
    userId: currentUser?.id,
  })

  const { pathname } = useLocation()
  const { lastLocation, setLastLocation } = React.useContext(LastLocationContext)

  React.useEffect(() => {
    if (!lastLocation) {
      setLastLocation(pathname)
    }
  }, [setLastLocation, lastLocation, pathname])

  return (
    <ProjectProvider>
      <PropertyProvider>
        <CurrentUserProvider>
          <ConversationsProvider>{children}</ConversationsProvider>
        </CurrentUserProvider>
      </PropertyProvider>
    </ProjectProvider>
  )
}
