import { Api, Config } from '@walter/shared'
import {
  BoxCenter,
  ConfirmModal,
  DashboardWrapperInner,
  EmptyState,
  GlobalLayout,
  Loading,
  DashboardLayout as SharedDashboardLayout,
  WebLogger,
} from '@walter/shared-web'
import * as React from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { OtherResidentAddRequestContent } from '../components/OtherResidentAddRequestContent'
import Menu from '../components/nav/Menu'
import Nav from '../components/nav/Nav'
import AuthContext from '../contexts/Auth'
import ProjectContext from '../contexts/Project'
import PropertyContext from '../contexts/Property'
import { DashboardProvider } from '../providers/DashboardProvider'
import { t } from '../utils/i18n'

export function DashboardLayout() {
  const { pathname } = useLocation()
  const { currentUser, loadingCurrentUserQuery, refetchCurrentUserQuery } = React.useContext(AuthContext)
  const [addUserRequest] = Api.useDoUserAddRequestResidentWebMutation()

  const { data: { user: detailedResident } = {}, loading: loadingDetailedResident } =
    Api.useResidentWithAddressResidentWebQuery({
      skip:
        !currentUser ||
        currentUser.pendingUserAddRequests.length === 0 ||
        !currentUser.pendingUserAddRequests[0].requestCreatorUser?.id,
      variables: {
        where: {
          id: currentUser?.pendingUserAddRequests[0]?.requestCreatorUser?.id as string,
        },
      },
    })

  const isUserAddRequest = React.useMemo(() => {
    return currentUser?.pendingUserAddRequests?.length > 0
  }, [currentUser])

  async function confirmAddUserRequest() {
    await addUserRequest({
      variables: { id: currentUser.pendingUserAddRequests[0].id, status: 'ADDED' },
    })
    await refetchCurrentUserQuery()
  }

  async function cancelAddUserRequest() {
    await addUserRequest({
      variables: { id: currentUser.pendingUserAddRequests[0].id, status: 'REJECTED' },
    })
    await refetchCurrentUserQuery()
  }

  if (!currentUser || loadingCurrentUserQuery) return <Loading />

  if (isUserAddRequest) {
    let requesterAddress = ''
    if (!loadingDetailedResident) {
      requesterAddress = detailedResident?.livingProperty
        ? `${detailedResident.livingProperty.building?.address?.address1}, ${detailedResident.livingProperty.building?.address?.city}, ${detailedResident.livingProperty.building?.address?.state}, ${detailedResident.livingProperty.building?.address?.country}, ${detailedResident.livingProperty.building?.address?.zip}`
        : ''

      if (!requesterAddress) {
        requesterAddress =
          detailedResident?.ownedProperties && detailedResident?.ownedProperties.length > 0
            ? `${detailedResident.ownedProperties[0].building?.address?.address1}, ${detailedResident.ownedProperties[0].building?.address?.city}, ${detailedResident.ownedProperties[0].building?.address?.state}, ${detailedResident.ownedProperties[0].building?.address?.country}, ${detailedResident.ownedProperties[0].building?.address?.zip}`
            : ''
      }
    }

    return loadingDetailedResident ? (
      <Loading />
    ) : (
      <ConfirmModal
        visible={true}
        onConfirm={confirmAddUserRequest}
        onClose={cancelAddUserRequest}
        isLoading={false}
        customDescription={' '}
        extraContent={OtherResidentAddRequestContent({
          isOwner: currentUser.pendingUserAddRequests[0].isOwner,
          project: currentUser.pendingUserAddRequests[0].targetedProject.name ?? '',
          address: `${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.address1}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.city}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.state}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.country}, ${currentUser.pendingUserAddRequests[0].targetedProject.building?.address?.zip}`,
          appartmentNumber: `${currentUser.pendingUserAddRequests[0].targetedProperty.address?.apartmentNumber}`,
          requesterAddress,
          requesterfullName: `${currentUser.pendingUserAddRequests[0].requestCreatorUser?.firstName} ${currentUser.pendingUserAddRequests[0].requestCreatorUser?.lastName}`,
        })}
      />
    )
  }

  return (
    <DashboardProvider>
      <GlobalLayout>
        <HasNoPropertiesWrapper>
          <Nav />
          <ValidManagingCompanyWrapper>
            <PropertyWrapper>
              <Menu />
              <DashboardWrapperInner>
                <SharedDashboardLayout data-test-id={getDataTestId(pathname)} isFullPage={pathname.includes('/chat')}>
                  <Outlet />
                </SharedDashboardLayout>
              </DashboardWrapperInner>
            </PropertyWrapper>
          </ValidManagingCompanyWrapper>
        </HasNoPropertiesWrapper>
      </GlobalLayout>
    </DashboardProvider>
  )
}

function getDataTestId(pathname: string) {
  if (pathname === '/' || pathname === '/all') {
    return 'Main_Dashboard'
  }
  const segments = pathname.split('/')

  if (segments.length < 3) {
    return `Unknown_Dashboard`
  }

  const route = segments[2] // Main Route Name
    .split('-') // Split in case of multiple words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Uppercase first letter
    .join('') // Join words

  return `${route}_Dashboard`
}

function HasNoPropertiesWrapper({ children }: { children: React.ReactNode }) {
  const { uniqProperties } = React.useContext(PropertyContext)
  const { logout } = React.useContext(AuthContext)
  const navigate = useNavigate()

  if (uniqProperties.length === 0) {
    return (
      <BoxCenter>
        <EmptyState
          icon="house"
          heading={t('general:profile-no-longer-linked-to-any-units-heading')}
          description={t('general:profile-no-longer-linked-to-any-units-description')}
          primaryAction={{
            label: t('navigation:logout'),
            handleClick: async () => {
              await logout()
              navigate('/auth/login')
            },
          }}
        />
      </BoxCenter>
    )
  }

  return <>{children}</>
}

function ValidManagingCompanyWrapper({ children }: { children: React.ReactNode }) {
  const { currentProject } = React.useContext(ProjectContext)

  if (
    currentProject &&
    (!currentProject.managingCompany || currentProject.managingCompany.id === Config.transitionManagingCompanyId)
  ) {
    return (
      <BoxCenter>
        {' '}
        <EmptyState icon="close" heading={t('general:managing-company-no-longer-use-usewalter')} />
      </BoxCenter>
    )
  }

  return <>{children}</>
}

function PropertyWrapper({ children }: { children: React.ReactNode }) {
  const { loadingProperty, errorLoadingPropertyQuery } = React.useContext(PropertyContext)
  const { loadingProject, errorLoadingProjectQuery } = React.useContext(ProjectContext)

  const error = errorLoadingPropertyQuery || errorLoadingProjectQuery

  React.useEffect(() => {
    if (error) {
      WebLogger.captureError(error)
    }
  }, [error])

  if (error) {
    return (
      <BoxCenter>
        <EmptyState heading="Error" description="Couldn't load your property" />
      </BoxCenter>
    )
  }

  if (loadingProperty || loadingProject) {
    return <Loading />
  }

  return <>{children}</>
}
