import { LoaderFunctionArgs, redirect } from 'react-router-dom'
import { getCurrentUser } from './api'

export async function loginForRetoolLoader(args: LoaderFunctionArgs) {
  try {
    const currentUser = await getCurrentUser()
    if (!currentUser) return null

    const searchParams = new URL(args.request.url).searchParams
    const emailFromParams = searchParams.get('email')

    if (!emailFromParams || emailFromParams === currentUser.email) {
      throw new Error(`/`, { cause: 'already-logged-in' })
    }
    return null
  } catch (err) {
    if (err instanceof Error) {
      if (err.cause === 'already-logged-in') {
        throw redirect(err.message)
      }
      if (err.message.startsWith('Forbidden resource')) {
        return null
      }
    }
    console.error('Error:', 'loginForRetoolLoader.ts', err)
    return null
  }
}
