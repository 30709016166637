import flatten from 'lodash/flatten'
import merge from 'lodash/merge'

type Truthy<T> = T extends false | '' | 0 | null | undefined ? never : T

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value
}

export const mergeStyles = (...styles: unknown[]) => merge(flatten([...styles]))
