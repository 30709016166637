import { useAtom, useAtomValue } from 'jotai'
import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'
import { MessagingService, Channel } from '../MessagingService'
import {
  conversationsSearchValueAtom,
  initialLoadingAtom,
  privateChannelsMapAtom,
  publicChannelsMapAtom,
} from '../atoms'

interface UseResidentConversationsArgs {
  selectedProjectId: string
  selectedPropertyId: string
}

/**
 * @warning Must be called after `useMessagingService`
 * @returns Grouped/sorted conversations and utilities
 */
export const useResidentConversations = ({ selectedProjectId, selectedPropertyId }: UseResidentConversationsArgs) => {
  const isLoading = useAtomValue(initialLoadingAtom)
  const publicChannelsMap = useAtomValue(publicChannelsMapAtom)
  const privateChannelsMap = useAtomValue(privateChannelsMapAtom)
  const [searchValue, setSearchValue] = useAtom(conversationsSearchValueAtom)

  const publicChannels = useMemo(() => Array.from(publicChannelsMap.values()) as Channel[], [publicChannelsMap])

  const channelsByProject = useMemo(() => {
    return groupBy(publicChannels, (channel) => {
      return MessagingService.projectIdFromChannelUrl(channel.url)
    })
  }, [publicChannels, privateChannelsMap])

  const conversations = useMemo(() => {
    const projectChannels = channelsByProject[selectedProjectId] ?? []

    const propertyChannels = projectChannels.filter((channel) => {
      const channelIsDm = MessagingService.channelIsDm(channel.url)
      const propertyId = MessagingService.entityIdFromChannelUrl(channel.url)
      return channelIsDm || propertyId === selectedPropertyId
    })

    const ordered = orderBy(
      propertyChannels,
      [
        (channel) => channel.unreadMessageCount,
        (channel) => channel.lastMessage?.createdAt || 0,
        (channel) => channel.name,
      ],

      ['desc', 'desc', 'asc'],
    )

    return searchValue
      ? ordered.filter((channel) => channel.name?.toLocaleLowerCase().includes(searchValue.toLowerCase()))
      : ordered
  }, [selectedProjectId, channelsByProject, searchValue, selectedPropertyId])

  return {
    isLoading,
    conversations,
    searchValue,
    setSearchValue,
  }
}
