import { ApolloError } from '@apollo/client'
import { Api } from '@walter/shared'
import React from 'react'
import { useParams } from 'react-router-dom'

export type ProjectContextType = {
  currentProject?: NonNullable<Api.ProjectDetailedResidentWebQuery['property']['building']>['project'] | null
  loadingProject: boolean
  errorLoadingProjectQuery?: ApolloError
}

const ProjectContext = React.createContext<ProjectContextType>({} as ProjectContextType)

export default ProjectContext

interface ProjectContextProps {
  children: React.ReactNode
}

export function ProjectProvider({ children }: ProjectContextProps) {
  const { propertyId } = useParams<{ propertyId: string }>()

  const {
    data: { property } = {},
    loading: loadingProject,
    error: errorLoadingProjectQuery,
  } = Api.useProjectDetailedResidentWebQuery({
    variables: {
      where: {
        id: propertyId,
      },
    },
    skip: !propertyId || propertyId === 'profile',
  })

  const currentProject = property?.building?.project

  return (
    <ProjectContext.Provider value={{ currentProject, loadingProject, errorLoadingProjectQuery }}>
      {children}
    </ProjectContext.Provider>
  )
}
