import { ApolloProvider } from '@apollo/client'
import { Colors } from '@walter/shared'
import {
  LastLocationProvider,
  MobileBanner,
  PopoverProvider,
  ToastProvider,
  getLanguage,
  i18n,
} from '@walter/shared-web'
import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { SkeletonTheme } from 'react-loading-skeleton'
import { Outlet } from 'react-router-dom'
import { AuthProvider } from '../contexts/Auth'
import { apolloClient } from '../utils/apollo-client'

const IS_FRENCH = getLanguage().toLowerCase().startsWith('fr')

const MOBILE_URLS = {
  iconImgUrl:
    'https://is1-ssl.mzstatic.com/image/thumb/Purple113/v4/ed/9b/46/ed9b460e-50db-9736-58a3-fe60a97cc870/AppIcon-0-0-1x_U007emarketing-0-0-0-7-0-0-sRGB-0-0-0-GLES2_U002c0-512MB-85-220-0-0.png/540x540bb.jpg',
  iosUrl: 'https://apps.apple.com/ca/app/usewalter/id1383397943?itsct=apps_box_badge&amp;itscg=30200',
  androidUrl: `https://play.google.com/store/apps/details?id=com.janitor.app&hl=${IS_FRENCH ? 'fr' : 'en'}_CA&gl=CA`,
}

export function RootProvider() {
  return (
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={apolloClient}>
        <ToastProvider>
          <PopoverProvider>
            <LastLocationProvider>
              <AuthProvider>
                <SkeletonTheme baseColor={Colors.white} highlightColor={Colors.offWhite}>
                  <Outlet />
                  <MobileBanner {...MOBILE_URLS} />
                </SkeletonTheme>
              </AuthProvider>
            </LastLocationProvider>
          </PopoverProvider>
        </ToastProvider>
      </ApolloProvider>
    </I18nextProvider>
  )
}
