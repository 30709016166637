/**
 * Separated the modal and the content because we're reusing the content in another place
 */
import { Api } from '@walter/shared'
import React, { useContext, useRef } from 'react'
import { AskManagerModal, Fieldset, FieldsetItem, Input } from '..'
import { ToastContext } from '../../contexts'
import { t } from '../../utils'
import { Button } from '../Button'

type ConfirmPhoneNumberContentProps = {
  userPhone: string
  codeConfirmed?: () => void
}

export const ConfirmPhoneNumberContent = (props: ConfirmPhoneNumberContentProps) => {
  const { showToast } = useContext(ToastContext)
  const isSendingCodeRef = useRef(false)

  const codeInputRef = useRef<HTMLInputElement>(null)
  const askManagerModalRef = useRef()

  const [sendPhoneVerificationCodeMutation, { loading: sendingVerificationCode }] =
    Api.useSendAuthVerificationCodePhoneNumberMutation()

  const [confirmPhoneNumberCode, { loading: confirmingVerificationCode }] = Api.useVerifyAuthCodePhoneNumberMutation()

  React.useEffect(() => {
    if (isSendingCodeRef.current) return
    sendCode()
  }, [])

  async function sendCode() {
    try {
      isSendingCodeRef.current = true
      await sendPhoneVerificationCodeMutation({ variables: { phoneNumber: props.userPhone } })
      showToast('positive', t('toast:confirm-phone-code-sent-title'), t('toast:confirm-phone-code-sent-description'))
    } catch (error) {
      showToast('negative', error instanceof Error ? error?.message : t('error'))
    } finally {
      isSendingCodeRef.current = false
    }
  }

  async function confirmCode() {
    try {
      await confirmPhoneNumberCode({
        variables: { phoneNumber: props.userPhone, code: codeInputRef.current?.value ?? '' },
      })
      showToast(
        'positive',
        t('toast:confirm-phone-code-confirmed-title'),
        t('toast:confirm-phone-code-confirmed-description'),
      )
      if (props.codeConfirmed) {
        props.codeConfirmed()
      }
    } catch (error) {
      showToast('negative', error instanceof Error ? error?.message : t('error'))
    }
  }

  return (
    <>
      <p>
        {t('general:confirm-phone-description')} <b>{props.userPhone}</b>
      </p>
      <Fieldset>
        <FieldsetItem>
          <Input
            // @ts-ignore TODO: typing issue ref
            ref={codeInputRef}
            placeholder={t('general:enter-code-input-placeholder')}
            rightAction={{
              isLoading: confirmingVerificationCode,
              label: t('general:confirm-code'),
              theme: 'primary',
              onClick: confirmCode,
            }}
          />
        </FieldsetItem>
      </Fieldset>
      <Button theme="link" isLoading={sendingVerificationCode} onClick={sendCode}>
        {t('general:resend-code')}
      </Button>
      <br />
      {/* @ts-ignore TODO: typing issue askManagerModalRef */}
      <Button theme="link" isLoading={sendingVerificationCode} onClick={() => askManagerModalRef.current.open()}>
        {t('general:not-your-phone-number')}
      </Button>
      <AskManagerModal ref={askManagerModalRef} />
    </>
  )
}
