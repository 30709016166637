/**
 * Separated the modal and the content because we're reusing the content in another place
 */
import React from 'react'
import { t } from '../../utils'
import { Modal } from '../Modal'
import { ConfirmEmailContent } from './ConfirmEmailContent'

type ConfirmEmailModalProps = {
  close: (event: React.MouseEvent<HTMLButtonElement>) => any
  visible: boolean
  userEmail: string
  codeConfirmed?: () => void
}

export const ConfirmEmailModal = ({ close, visible, userEmail, codeConfirmed }: ConfirmEmailModalProps) => {
  return (
    <Modal title={t('modal:confirm-email-title')} visible={visible} close={close}>
      {/* Adding "visible &&" to trigger the send code only when the modal opens. Not the best UI/UX but works */}
      {visible && <ConfirmEmailContent userEmail={userEmail} codeConfirmed={codeConfirmed} />}
    </Modal>
  )
}
